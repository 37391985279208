import { useState, FC } from 'react'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'

import BuildsButton from 'shared/button'
import { isEmailValid } from '@jaymathew/utils/Validator/EmailValidator'

interface ResetPasswordDialogProps {
    closeDialogs: () => void
    shouldShowforgotPasswordDialog: boolean
    sendForgotPasswordEmail: (email: string) => void
}

const ResetPasswordDialog: FC<ResetPasswordDialogProps> = ({
    closeDialogs,
    shouldShowforgotPasswordDialog,
    sendForgotPasswordEmail,
}) => {

    const [hasClickedContinue, setHasClickedContinue] = useState(false)
    const [email, setEmail] = useState('')

    const onResendClick = () => {
        setHasClickedContinue(true)

        if (isEmailValid(email)) {
            sendForgotPasswordEmail(email)
        }
    }

    return (
        <Dialog
            open={shouldShowforgotPasswordDialog}
            onClose={closeDialogs}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">
                Reset Password
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    Enter the email address you used to register with Builds360
                    and we'll send you instructions to reset your password.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="email"
                    label="Email Address"
                    type="email"
                    fullWidth
                    value={email}
                    onChange={(event) => setEmail(event.target.value.trim())}
                    error={hasClickedContinue && !isEmailValid(email)}
                    helperText={hasClickedContinue && !isEmailValid(email) ? 'This field is required and must be a vaild email address.' : '' }
                />
            </DialogContent>
            <DialogActions>
                <BuildsButton
                    title="Cancel"
                    variant="outlined"
                    onClick={closeDialogs}
                />
                <BuildsButton
                    title="Send Instructions"
                    onClick={onResendClick}
                />
            </DialogActions>
        </Dialog>
    )
}

export default ResetPasswordDialog
