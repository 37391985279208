import * as Types from 'resources/types'
import { chargeActions } from '../actions/chargeActions'

const ChargeReducer = (state: Types.chargesReduxState = {
    subscriptions: undefined,
    sources: undefined,
    invoices: [],
    setupIntent: undefined,
    paymentIntent: undefined,
    pricingPlans: undefined,
}, action: chargeActions) => {
    
    switch (action.type) {
        case 'GET_SUBSCRIPTIONS_SUCCESS':
            
            state = {
                ...state,
                subscriptions: action.subscription,
            }

            break

        case 'GET_PAYMENT_METHODS_SUCCESS':
            
            state = {
                ...state,
                sources: action.sources,
            }

            break

        case 'GET_INVOICES_SUCCESS':
            
            const newInvoiceData = action.invoices.data.map((el: any) => {
                return {
                    hostedURL: el.hosted_invoice_url,
                    status: el.status,
                    id: el.id,
                    total: el.total,
                    paid: el.paid,
                    date: new Date(el.created * 1000),
                }
            })

            state = {
                ...state,
                invoices: newInvoiceData,
            }

            break

        case 'SWITCH_SUBSCRIPTION_SUCCESS':
            
            state = {
                ...state,
                subscriptions: action.subscription,
            }

            break

        case 'SETUP_INTENT_SUCCESS':
            
            state = {
                ...state,
                setupIntent: action.setupIntent,
            }
            break

        case 'GET_PRICING_PLANS_SUCCESS':
            
            state = {
                ...state,
                pricingPlans: action.pricingPlans.data,
            }

            break

        case 'SET_CURRENT_STATE_SIGNNED_OUT':
            
            state = {
                subscriptions: undefined,
                sources: undefined,
                invoices: [],
                setupIntent: undefined,
                paymentIntent: undefined,
                pricingPlans: undefined,
            }
            break

        default:
            break
    }

    return state
}

export default ChargeReducer
