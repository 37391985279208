import * as Types from 'resources/types'
import { messagesActions } from '../actions/messagesAction'

const messagesReducer = (state: Types.messagesReduxState = {
    allLastMessages: [],
    allMessages: [],
}, action: messagesActions) => {
    switch (action.type) {
        case 'GET_ALL_LAST_MESSAGES_SUCCESS':
            
            state = {
                ...state,
                allLastMessages: action.messagesData,
            }

            break

        case 'SEND_MESSAGE_SUCCESS':
            // since the message table view is tied to a real time firestore feature we dont need to add the new message like this.

            break

        case 'GET_PROJECT_MESSAGES_SUCCESS':
            // new messages
            const newMessages = action.messagesData

            //create a copy of the allMessages variable
            const getMessagesAllMessagesCopy = [...state.allMessages]

            //check if any newMessages were passed, if so they shousl all be from the same project.
            //match the first projectID and put all messages there
            const resultIndex = getMessagesAllMessagesCopy.findIndex(element => element.projectID === newMessages[0].projectID)

            if (resultIndex >= 0) {
                //project found, insert message inot existing array
                getMessagesAllMessagesCopy[resultIndex].messages = newMessages
            } else {
                //no project found, create new one
                getMessagesAllMessagesCopy.push({ projectID: newMessages[0].projectID!, messages: newMessages })
            }

            state = {
                ...state,
                allMessages: getMessagesAllMessagesCopy,
            }

            break

        case 'SET_CURRENT_STATE_SIGNNED_OUT':
            
            state = {
                allLastMessages: [],
                allMessages: [],
            }
            break

        default:
            break
    }

    return state
}

export default messagesReducer
