import { useState, FC, useEffect } from 'react'
import { auth } from '@jaymathew/apis/FirebaseUtilities/FirebaseConfig'
import { Unsubscribe, onAuthStateChanged, setPersistence, signInWithEmailAndPassword, browserSessionPersistence, sendPasswordResetEmail } from 'firebase/auth'
import * as Constants from 'resources/constants'
import { loginAnalytics, resendVerificationEmail } from 'api/NetworkManager'

import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import {
    getOfficesForUser,
    setCurrentCompany,
} from 'redux/actions/companyActions'
import {
    getEndUserDataForCurrentCompany,
} from 'redux/actions/userActions'

import Login from './components/login'
import ResetPasswordDialog from './components/resetPasswordDialog'
import SelectCompanyDialog from './components/selectCompanyDialog'
import VerificationNeededDialog from './components/verificationNeededDialog'
import GenericDialog from 'shared/genericDialog'
import GenericSnackbar from 'shared/genericSnackbar'
// import useLogin from './useLogin'

//used for types
import { AppState } from 'redux/reducers'
import * as Types from 'resources/types'

let unsubscribe: Unsubscribe

const LoginPage: FC = () => {


    const [password, setPassword] = useState('')
    const [shouldShowErrorDialog, setShouldShowErrorDialog] = useState(false)
    const [errorObject, setErrorObject] = useState<Types.errorObject>({ title: '', message: '' })
    const [shouldShowAccountNeedsVerifiedDialog, setShouldShowAccountNeedsVerifiedDialog] = useState(false)
    const [shouldShowforgotPasswordDialog, setShouldShowforgotPasswordDialog] = useState(false)
    const [shouldShowCompanyListDialog, setShouldShowCompanyListDialog] = useState(false)
    const [shouldShowSnackBar, setShouldShowSnackBar] = useState(false)
    const [snackBarMessage, setSnackBarMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [selectedCompanyID, setSelectedCompanyID] = useState('')

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const locationState = location.state as Record<string, string> || undefined
    const redirectURL = locationState ? locationState.redirectURL : undefined
    // const {} = useLogin({ getOfficesForUser: })

    const companies = useSelector((state: AppState) => state.companies)

    useEffect(() => {
        unsubscribe = onAuthStateChanged(auth, user => {
            if (user) {
                // User is signed in.
                if (user.emailVerified) {
                    setLoading(true)

                    Promise.resolve(dispatch(getOfficesForUser())).then(returnedConnectedCompanies => {
                        // deal with the connected companies
                        const connectedCompanies:Types.Company[] = returnedConnectedCompanies as any
                        if (connectedCompanies && connectedCompanies.length > 1) {
                            setShouldShowCompanyListDialog(true)
                            setSelectedCompanyID(connectedCompanies![0].id!)
                            setLoading(false)
                        } else if (connectedCompanies && connectedCompanies.length === 1) {
                            setSelectedCompanyID(connectedCompanies![0].id!)
                            setLoading(false)
                            navToApp(connectedCompanies![0].id)
                        } else {
                            //no active companies
                            setErrorObject({
                                title: 'No Active Company',
                                message: "We couldn't find any active companies attached to your profile. If you believe this is an error please contact the company you believe to be enrolled with.",
                            })
                            setShouldShowErrorDialog(true)
                            setLoading(false)
                        }
                    }).catch(error => {
                        setErrorObject({
                            title: 'Error Retrieving Data',
                            message: error.message,
                        })
                        setShouldShowErrorDialog(true)
                        setLoading(false)
                    })
                } else {
                    setShouldShowAccountNeedsVerifiedDialog(true)
                }
            }
        })
        return () => unsubscribe()
    }, [])

    const navCreateAccount = () => {
        navigate(Constants.createAccountPage)
    }

    const signIn = async (username: string, password: string) => {

        if (!username || !password) {
            setShouldShowErrorDialog(true)
            setErrorObject({
                title: 'Missing Information',
                message: 'Please enter your email and password before logging in.',
            })
            setPassword('')
            return
        }

        try {
            setLoading(true)

            await Promise.all([
                setPersistence(auth, browserSessionPersistence),
                signInWithEmailAndPassword(auth, username, password),
            ])

            setLoading(false)
        } catch (error) {
            // Handle Errors here.
            const errorCode = (error as any).code
            const errorMessage = (error as Error).message
            // [START_EXCLUDE]
            if (errorCode === 'auth/wrong-password') {
                setShouldShowErrorDialog(true)
                setErrorObject({
                    title: '',
                    message: 'The username or password does not match our records',
                })
                setLoading(false)
                setPassword('')
            } else {
                setShouldShowErrorDialog(true)
                setErrorObject({ title: '', message: errorMessage })
                setLoading(false)
                setPassword('')
            }
        }
    }

    async function navToApp(defaultCompanyID?:string) {
        try {
            setShouldShowCompanyListDialog(false)

            Promise.resolve(dispatch(setCurrentCompany(defaultCompanyID || selectedCompanyID)))

            setLoading(true)
            const values = await Promise.all([
                loginAnalytics(defaultCompanyID || selectedCompanyID),
                Promise.resolve(dispatch(getEndUserDataForCurrentCompany(defaultCompanyID || selectedCompanyID))),
            ])
            const user = values[1] as Types.User | undefined
            setLoading(false)

            if (redirectURL) {
                navigate(redirectURL)
            } else {
                if (user && (user.isAccountOwner || user.isAdmin || user.isEmployee)) {
                    navigate(Constants.projectsPage)
                } else {
                    //for clients
                    navigate(Constants.profilePage)
                }
            }
        } catch (error) {
            if (error instanceof Error) {
                setLoading(false)
                setShouldShowErrorDialog(true)
                setErrorObject({
                    title: 'Error Retrieving Data',
                    message: error.message,
                })
            }
        }
    }

    const showforgotPasswordDialog = () => {
        setShouldShowforgotPasswordDialog(true)
    }

    const closeDialogs = () => {
        setShouldShowErrorDialog(false)
        setShouldShowforgotPasswordDialog(false)
        setShouldShowCompanyListDialog(false)
        setShouldShowSnackBar(false)

    }

    const closeVerificationEmailDialog = () => {
        setShouldShowAccountNeedsVerifiedDialog(false)
        auth.signOut()
    }

    const sendVerificationEmail = async () => {
        setShouldShowSnackBar(true)
        setSnackBarMessage('Verification email sent')
        setShouldShowAccountNeedsVerifiedDialog(false)


        try {
            await resendVerificationEmail()
            auth.signOut()
        } catch (error) {
            setShouldShowSnackBar(true)
            setSnackBarMessage('There was an error sending verification email')

            auth.signOut()
        }
    }

    const sendForgotPasswordEmail = async (email: string) => {
        try {
            setShouldShowSnackBar(true)
            setSnackBarMessage('Password reset email sent')
            setShouldShowforgotPasswordDialog(false)


            await sendPasswordResetEmail(auth, email)
        } catch (error) {
            setShouldShowSnackBar(true)
            setSnackBarMessage('There was an error sending reset email.')
        }
    }

    const companyRadioButtonChange = (event: any) => {
        setSelectedCompanyID(event.target.value)
    }


    return (
        <div>
            <Login
                password={password}
                navCreateAccount={navCreateAccount}
                signIn={signIn}
                showforgotPasswordDialog={showforgotPasswordDialog}
                loading={loading}
            />

            {shouldShowforgotPasswordDialog && (
                <ResetPasswordDialog
                    closeDialogs={closeDialogs}
                    shouldShowforgotPasswordDialog={shouldShowforgotPasswordDialog}
                    sendForgotPasswordEmail={sendForgotPasswordEmail}
                />
            )}

            {shouldShowCompanyListDialog && (
                <SelectCompanyDialog
                    shouldShowCompanyListDialog={shouldShowCompanyListDialog}
                    selectCompany={navToApp}
                    arrayOfCompanies={companies.connectedCompanies}
                    selectCompanyRadioButtonChange={companyRadioButtonChange}
                    selectedCompanyID={selectedCompanyID}
                />
            )}

            {shouldShowAccountNeedsVerifiedDialog && (
                <VerificationNeededDialog
                    shouldShowAccountNeedsVerifiedDialog={shouldShowAccountNeedsVerifiedDialog}
                    closeDialogs={closeVerificationEmailDialog}
                    sendVerificationEmail={sendVerificationEmail}
                />
            )}

            {shouldShowErrorDialog && (
                <GenericDialog
                    shouldShow={shouldShowErrorDialog}
                    closeDialog={closeDialogs}
                    title={errorObject.title}
                    message={errorObject.message}
                />
            )}

            {shouldShowSnackBar && (
                <GenericSnackbar
                    shouldShowSnackbar={shouldShowSnackBar}
                    message={snackBarMessage}
                    closeSnackbar={closeDialogs}
                />
            )}
        </div>
    )
}

export default LoginPage
