export type apiStatusActions =
    | { type: 'BEGIN_API_CALL' }
    | { type: 'API_CALL_ERROR' }
    | { type: 'ZERO_OUT_API_CALLS' }
    | { type: string }

export const beginApiCall = () => {
    return {
        type: 'BEGIN_API_CALL',
    }
}

export const apiCallError = () => {
    return {
        type: 'API_CALL_ERROR',
    }
}

export const zeroOutApiCall = () => {
    return {
        type: 'ZERO_OUT_API_CALLS',
    }
}
