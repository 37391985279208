import { Fragment, FC } from 'react'
import { SxProps, Theme } from '@mui/system';
import Logo from 'assets/LogoIcon.svg'
import { Login as SignIn } from '@jaymathew/modules/login/Login'
import Paper from '@mui/material/Paper'
import { Div } from '@jaymathew/elements/Div'


const styles = {
    root: {
        backgroundColor: 'background.default',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: { xs: 'stretch', sm: 'center' },
        padding: { xs: 0, sm: 2 },
    } as SxProps<Theme>,

    paper: {
        backgroundColor: 'background.paper',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: { xs: '100vw', sm: 460 },
    } as SxProps<Theme>,
}


interface LoginProps {
    loading: boolean
    userName?: string
    password?: string
    showforgotPasswordDialog: () => void
    navCreateAccount: () => void
    signIn: (username: string, password: string) => Promise<void>
}

const Login: FC<LoginProps> = ({
    loading,
    userName,
    password,
    showforgotPasswordDialog,
    navCreateAccount,
    signIn,
}) => {

    return (
        <Fragment>
            <Div sx={styles.root}>
                <Paper elevation={3} sx={styles.paper}>
                    <SignIn
                        logo={Logo}
                        loading={loading}
                        username={userName}
                        password={password}
                        showforgotPasswordDialog={showforgotPasswordDialog}
                        navCreateAccount={navCreateAccount}
                        signIn={signIn}
                    />
                </Paper>
            </Div>
        </Fragment>
    )
}

export default Login
