import { FC } from 'react'
import * as Types from 'resources/types'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'

import BuildsButton from 'shared/button'

interface LoginDialogsProps {
    shouldShowCompanyListDialog: boolean
    selectCompany: () => Promise<void>
    arrayOfCompanies?: Types.Company[]
    selectCompanyRadioButtonChange: (event: any) => void
    selectedCompanyID: string
}

const LoginDialogs: FC<LoginDialogsProps> = ({
    shouldShowCompanyListDialog,
    selectCompany,
    arrayOfCompanies,
    selectCompanyRadioButtonChange,
    selectedCompanyID,
}) => {
    const selectCompanyButtonPressed = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            selectCompany()
        }
    }

    return (
        <Dialog
            disableEscapeKeyDown
            maxWidth="xs"
            open={shouldShowCompanyListDialog}
            aria-labelledby="confirmation-dialog-title">
            <DialogTitle id="confirmation-dialog-title">
                Select A Company
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    We see you are connected with multiple companies, Please
                    select one to sign in with.
                </DialogContentText>

                <FormControl component="div" required>
                    <RadioGroup
                        aria-label="company-list"
                        name="company-list-name"
                        value={selectedCompanyID}
                        onChange={selectCompanyRadioButtonChange}
                        onKeyPress={selectCompanyButtonPressed}
                    >
                        {arrayOfCompanies &&
                            arrayOfCompanies.map(({ name, id }) => (
                                <FormControlLabel
                                    value={id}
                                    key={name}
                                    control={<Radio color="primary" />}
                                    label={name as string}
                                />
                            ))}
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <BuildsButton title="Select" onClick={selectCompany} />
            </DialogActions>
        </Dialog>
    );
}

export default LoginDialogs
