import * as React from 'react'
import ReactDOM from 'react-dom'
import '@jaymathew/apis/FirebaseUtilities/FirebaseConfig'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import './index.css'
import * as serviceWorker from './serviceWorker'
import Routes from './routes/routes'

//register bugsnag
Bugsnag.start({
    apiKey: '9043c11f6237189198707003b4fb5d86',
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['production'],
})
// wrap your entire app tree in the ErrorBoundary provided
const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)

ReactDOM.render(
    <ErrorBoundary>
        <Routes />
    </ErrorBoundary>,
    document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
