import { firestore as db, auth } from '@jaymathew/apis/FirebaseUtilities/FirebaseConfig'
import { getDoc, doc, DocumentData, QueryDocumentSnapshot, collection, query, where, orderBy, startAfter, limit, getDocs, serverTimestamp, setDoc, updateDoc, deleteDoc } from "firebase/firestore";
import * as Types from 'resources/types'
import { encodeAndTokenize } from '@jaymathew/utils/Search'

export const getCompaniesFor = async () => {
    try {
        const user = auth.currentUser

        const companiesDocRef = doc(db, "usernameCompanies", user!.uid);
        const companiesDoc = await getDoc(companiesDocRef);

        if (!companiesDoc.exists()) {
            throw new Error('The supplied company id did not match any company.')
        }

        const companies = companiesDoc.data() as Types.UsernameCompanies
        return companies.companyID
    } catch (error) {
        throw error
    }
}

export const getCompanyObject = async (companyID: string) => {
    try {

        const companyDocRef = doc(db, "companies", companyID);
        const companyDoc = await getDoc(companyDocRef);

        if (!companyDoc.exists()) {
            throw new Error('The supplied company id did not match any company.')
        }

        return companyDoc.data() as Types.Company
    } catch (error) {
        throw error
    }
}

export const getEndUserInfoWith = async (companyID: string) => {
    try {
        const user = auth.currentUser

        const userDataRef = doc(db, `companies/${companyID}/users`, user!.uid);
        const userData = await getDoc(userDataRef);

        if (!userData.exists()) {
            throw new Error('The supplied user id did not match any user.')
        }

        return userData.data() as Types.User
    } catch (error) {
        throw error
    }
}

export const getTimelineProject = async (companyID: string, projectID: string) => {
    try {

        const projectRef = doc(db, `companies/${companyID}/projects/${projectID}/timelineProject`, 'timelineProject');
        const projectData = await getDoc(projectRef);

        if (!projectData.exists()) {
            return {}
        }

        return projectData.data() as Types.TimelineProject
    } catch (error) {
        throw error
    }
}

export const getProjects = async (companyID: string, sortBy: keyof Types.Project, sort: 'asc' | 'desc' = 'asc', limitNumber = 20, folderID?: string, searchTerm?: string, lastDocument?: QueryDocumentSnapshot<DocumentData>) => {
    try {

        const collectionRef = collection(db, `companies/${companyID}/projects`)
        let projectsCollectionRef

        if (!searchTerm || searchTerm === '') {
            if (folderID) {
                projectsCollectionRef = query(collectionRef, where("folderID", "==", folderID), orderBy(sortBy.toString(), sort))
            } else {
                projectsCollectionRef = query(collectionRef, orderBy(sortBy.toString(), sort))
            }
        } else {
            const encodedSearchTerm = await encodeAndTokenize(searchTerm)

            projectsCollectionRef = query(collectionRef, where("searchTerms", "array-contains-any", encodedSearchTerm), orderBy(sortBy.toString(), sort))

        }

        if (lastDocument) {
            projectsCollectionRef = query(projectsCollectionRef, startAfter(lastDocument))
        }

        projectsCollectionRef = query(projectsCollectionRef, limit(limitNumber))
        const projectsCollection = await getDocs(projectsCollectionRef);

        if (projectsCollection.empty) {
            return []
        }

        const projects: Types.Project[] = projectsCollection.docs.map(doc => doc.data())

        return [projects, projectsCollection.docs[projectsCollection.docs.length - 1]] as [Types.Project[], QueryDocumentSnapshot<DocumentData>]
    } catch (error) {
        throw error
    }
}

export const getTimelinePosts = async (companyID: string, projectID: string) => {

    try {
        const collectionRef = collection(db, `companies/${companyID}/projects/${projectID}/posts`)
        const postsCollectionRef = query(collectionRef, orderBy('createdOn', 'desc'))
        const postsCollection = await getDocs(postsCollectionRef);

        if (postsCollection.empty) {
            return []
        }

        const posts: Types.Post[] = postsCollection.docs.map(doc => doc.data())

        return posts
    } catch (error) {
        throw error
    }
}

export const getAssetsForProject = async (companyID: string, projectID: string, postID: string) => {
    try {
        const assetsRef = collection(db, `companies/${companyID}/projects/${projectID}/assets`)
        const assetsCollectionRef = query(assetsRef, where('postID', '==', postID))
        const assetsCollection = await getDocs(assetsCollectionRef);

        if (assetsCollection.empty) {
            return []
        }

        const assets: Types.Asset[] = assetsCollection.docs.map(doc => doc.data())

        return assets
    } catch (error) {
        throw error
    }
}

export const getAllUsers = async (companyID: string) => {
    try {
        const assetsCollectionRef = collection(db, `companies/${companyID}/users`)
        const userCollection = await getDocs(assetsCollectionRef);

        if (userCollection.empty) {
            return []
        }

        const users: Types.User[] = userCollection.docs.map(doc => doc.data())

        return users
    } catch (error) {
        throw error
    }
}

export const getTags = async (companyID: string, limitNumber = 10, searchTerm?: string, lastDocument?: QueryDocumentSnapshot<DocumentData>) => {
    try {

        const collectionRef = collection(db, `companies/${companyID}/tags`)
        let tagsCollectionRef

        if (!searchTerm || searchTerm === '') {
            tagsCollectionRef = query(collectionRef, orderBy('name', 'asc'))
        } else {
            const encodedSearchTerm = await encodeAndTokenize(searchTerm)
            tagsCollectionRef = query(collectionRef, where("searchTerms", "array-contains-any", encodedSearchTerm), orderBy('name', 'asc'))
        }

        if (lastDocument) {
            tagsCollectionRef = query(tagsCollectionRef, startAfter(lastDocument))
        }

        tagsCollectionRef = query(tagsCollectionRef, limit(limitNumber))
        const tagCollection = await getDocs(tagsCollectionRef);

        if (tagCollection.empty) {
            return []
        }

        const tags: Types.Tag[] = tagCollection.docs.map(doc => doc.data())

        return [tags, tagCollection.docs[tagCollection.docs.length - 1]] as [Types.Tag[], QueryDocumentSnapshot<DocumentData>]
    } catch (error) {
        throw error
    }
}

// Posts
export const createPost = async (companyID: string, post: Types.Post) => {
    try {

        //create the postupdate record 
        const recordRef = doc(collection(db, `companies/${companyID}/postUpdateRecords`));

        //get ref to the furture document
        const postRef = doc(collection(db, `companies/${companyID}/projects/${post.projectID}/posts`));

        //add the id to the project object
        post.id = postRef.id

        //fill in the post record object 
        const updateRecord = {
            updatedOn: serverTimestamp(),
            updatedByID: post.createdByID,
            projectID: post.projectID,
            postID: post.id,
        }

        await Promise.all([
            setDoc(postRef, post),
            setDoc(recordRef, updateRecord)
        ])

        return post
    } catch (error) {
        throw error
    }
}

export const updateAPost = async (companyID: string, post: Types.Post) => {
    try {

        //create the postupdate record 
        const recordRef = doc(collection(db, `companies/${companyID}/postUpdateRecords`));
        //get ref to the furture document
        const postRef = doc(db, `companies/${companyID}/projects/${post.projectID}/posts`, post.id!);

        //fill in the post record object 
        const updateRecord = {
            updatedOn: serverTimestamp(),
            updatedByID: post.updatedByID,
            projectID: post.projectID,
            postID: post.id,
        }

        await Promise.all([
            updateDoc(postRef, post),
            setDoc(recordRef, updateRecord)
        ])

        return
    } catch (error) {
        throw error
    }
}

export const deleteAPost = async (companyID: string, post: Types.Post) => {
    try {
        //get ref to the furture document
        const postRef = doc(db, `companies/${companyID}/projects/${post.projectID}/posts`, post.id!)
        await deleteDoc(postRef)

        return
    } catch (error) {
        throw error
    }
}

// Message
export const sendMessage = async (companyID: string, userUID: string, message: string, projectID: string, pointer?: Types.Pointer) => {
    try {
        //get ref to the furture document
        const messageRef = doc(collection(db, `companies/${companyID}/projects/${projectID}/messages`))

        //add the id to the project object
        const payload: Types.Message = {
            id: messageRef.id,
            body: message,
            pointer,
            sentByID: userUID,
        }

        //remove the pointer if undefined, because firebase doesn't like undefineded values
        pointer === undefined && delete payload.pointer

        await setDoc(messageRef, payload)

        return payload
    } catch (error) {
        throw error
    }
}

//folders 
export const getAllFolders = async (companyID: string) => {
    try {
        const folderCollectionRef = collection(db, `companies/${companyID}/folders`)
        const folderCollection = await getDocs(folderCollectionRef);

        if (folderCollection.empty) {
            return []
        }

        const folders: Types.Folder[] = folderCollection.docs.map(doc => doc.data() as Types.Folder)

        return folders

    } catch (error) {
        throw error
    }
}
