import * as Types from 'resources/types'
import { projectsActions } from '../actions/projectsActions'

const ProjectsReducer = (state: Types.projectsReduxState = {
    selectedProject: {},
}, action: projectsActions) => {

    switch (action.type) {
        case 'SET_SELECTED_PROJECT':
            state = {
                ...state,
                selectedProject: action.project,
            }

            break

        case 'SET_CURRENT_STATE_SIGNNED_OUT':
            state = {
                selectedProject: {},
            }
            break

        default:
            break
    }

    return state
}

export default ProjectsReducer
