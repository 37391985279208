import * as Types from 'resources/types'
import { companyActions } from '../actions/companyActions'

const CompaniesReducer = (state: Types.companyReduxState = {
    currentCompany: {},
    connectedCompanies: [],
    companyAnalytics: {},
}, action: companyActions) => {
    
    switch (action.type) {
        case 'GET_COMPANIES_INFO_SUCCESS':
            
            //get only active companies
            const activeCompanies = action.companies.filter(company => company.active === true)

            state = {
                ...state,
                connectedCompanies: activeCompanies,
            }
            break

        case 'SET_CURRENT_COMPANY':
            
            const connectedCompanies = [...state.connectedCompanies!]

            if (connectedCompanies !== undefined) {
                const currentCompany = connectedCompanies.find(companyObject => companyObject.id === action.companyID)

                state = {
                    ...state,
                    currentCompany: currentCompany,
                }
            }

            break

        case 'SET_CURRENT_STATE_SIGNNED_OUT':
            
            state = {
                ...state,
                currentCompany: {},
                connectedCompanies: [],
                companyAnalytics: {},
            }
            break

        case 'UPDATE_CURRENT_COMPANY':
            
            const updatedCompany = {
                ...state.currentCompany,
                ...action.updatedCompany,
            }

            state = {
                ...state,
                currentCompany: updatedCompany,
            }
            break

        case 'BUILDERSIDE_TIMELINE_TEST_SUCCESS':
            
            const updatedTimelineCompany: Types.Company = {
                ...state.currentCompany,
                isBuilderSideTimeLineEnabled: action.didTestPass,
                builderTimelineURL: action.buildersideTimelineURL,
            }

            state = {
                ...state,
                currentCompany: updatedTimelineCompany,
            }

            break

        case 'ADD_COMPANY_CODE_TO_USER':
            
            const updatedConnectedCompany = state.connectedCompanies

            //check if company has already been added.
            if (updatedConnectedCompany) {
                if (!updatedConnectedCompany.some(company => company.id === action.newCompany.id)) {
                    //Object not found.
                    updatedConnectedCompany?.push(action.newCompany)
                }
            }

            state = {
                ...state,
                connectedCompanies: updatedConnectedCompany,
            }

            break

        case 'GET_COMPANIES_ANALYTICS_SUCCESS':
            
            state = {
                ...state,
                companyAnalytics: action.analytics,
            }

            break

        default:
            break
    }

    return state
}

export default CompaniesReducer
