import * as Types from 'resources/types'
import { appSettingsActions } from '../actions/appSettings'

const AppSettingsReducer = (state: Types.appSettingsReduxState = {
    darkTheme: window.matchMedia('(prefers-color-scheme: dark)').matches,
}, action: appSettingsActions ) => {
    
    switch (action.type) {
        case 'SHOULD_SHOW_DARK_MODE':
        
            state = {
                ...state,
                darkTheme: action.shouldShowDarkMode,
            }
            break

        default:
            break
    }

    return state
}

export default AppSettingsReducer
