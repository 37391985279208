import * as NetworkManager from 'api/NetworkManager'
import { auth, storage } from '@jaymathew/apis/FirebaseUtilities/FirebaseConfig'
import { ref, UploadMetadata, uploadBytes } from 'firebase/storage'
import * as Types from 'resources/types'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { beginApiCall, apiCallError } from './apiStatusActions'

export type userActions =
    | { type: 'GET_USER_DATA_SUCCESS'; userData: Types.User }
    | { type: 'GET_USER_PROJECTS_SUCCESS'; userProjects: Types.Project[] }
    | { type: 'USER_UPDATE_PROJECT_SUCCESS'; project: Types.Project }
    | { type: 'UPDATE_USER_SUCCESS'; userData: Types.User }
    | { type: 'UPDATE_USER_PHOTO_URL_SUCCESS'; photoUrl: Types.CustomFile }
    | { type: 'SET_CURRENT_STATE_SIGNNED_OUT' }
    | { type: 'ADD_COMPANY_CODE_TO_USER'; newCompany: Types.Company }

export const getUserDataForCurrentCompanySuccess = (userData: Types.User) => {
    return { type: 'GET_USER_DATA_SUCCESS', userData }
}

export const getUserProjectsForCurrentCompanySuccess = (userProjects: Types.Project[]) => {
    return { type: 'GET_USER_PROJECTS_SUCCESS', userProjects }
}

export const userUpdateProjectSuccess = (project: Types.Project) => {
    return { type: 'USER_UPDATE_PROJECT_SUCCESS', project }
}

export const updateUserSuccess = (userData: Types.User) => {
    return { type: 'UPDATE_USER_SUCCESS', userData }
}

export const updateUserPhotoURLSuccess = (photoUrl: Types.CustomFile) => {
    return { type: 'UPDATE_USER_PHOTO_URL_SUCCESS', photoUrl }
}

export const addCompnayCodeToUserSuccess = (newCompany: Types.Company) => {
    return { type: 'ADD_COMPANY_CODE_TO_USER', newCompany }
}

export const getEndUserProjectsForCurrentCompany = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any): Promise<void> => {
        try {
            const { companies }: { companies: Types.companyReduxState } = getState()
            dispatch(beginApiCall())

            const projects = await NetworkManager.getUsersProjects(companies.currentCompany!.id!)

            dispatch(getUserProjectsForCurrentCompanySuccess(projects))
        } catch (error) {
            dispatch(apiCallError())
            throw error
        }
    }
}

export const userUpdateProject = (project: Types.Project, password?: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any): Promise<void> => {
        try {
            const { companies }: { companies: Types.companyReduxState } = getState()
            dispatch(beginApiCall())

            await NetworkManager.userUpdateProject(companies.currentCompany!.id!, project, password)

            dispatch(userUpdateProjectSuccess(project))
        } catch (error) {
            dispatch(apiCallError())
            throw error
        }
    }
}

export const getEndUserDataForCurrentCompany = (companyID: string): ThunkAction<Promise<Types.User | undefined>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any): Promise<Types.User | undefined> => {
        return new Promise((resolve, reject) => {
            NetworkManager.getEndUserData(companyID).then(userData => {
                const currentUser = auth.currentUser
                if (currentUser === null) {
                    resolve(undefined)
                }
                return Promise.all([
                    currentUser!.getIdTokenResult(),
                    userData
                ])

            }).then(values => {
                if (!values[0].claims) {
                    resolve(undefined)
                }

                const userData = {
                    ...values[1],
                    ...values[0].claims[companyID] as object,
                }

                dispatch(getUserDataForCurrentCompanySuccess(userData))
                resolve(userData)
            }).catch(error => {
                reject(error)
            })
        })
    }
}

export const editUserAccount = (user: Types.User, password: string, profilePhoto?: Types.CustomFile): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any): Promise<void> => {
        try {
            const { companies, currentUser }: { companies: Types.companyReduxState, currentUser: Types.userReduxState } = getState()
            dispatch(beginApiCall())

            await NetworkManager.editAccount(companies.currentCompany!.id!, user, password)

            if (profilePhoto && profilePhoto.image && !profilePhoto.image.includes('googleapis.com')) {
                //there is a projectPhotoFile so save it

                // Points to this project's own image folder
                const userProfilePhotoFolderRef = ref(storage, `userProfilePhotoTemp/${user.fullName}ProfilePhoto`)

                // Create file metadata including the content type
                const metadata: UploadMetadata = {
                    customMetadata: {
                        companyID: companies.currentCompany!.id!,
                        userToken: currentUser.currentUser.uid!,
                    },
                }

                // let blob = await fetch(profilePhoto.image).then(r => r.blob());
                await uploadBytes(userProfilePhotoFolderRef, profilePhoto.file!, metadata)
            }

            dispatch(updateUserSuccess(user))
        } catch (error) {
            dispatch(apiCallError())
            throw error
        }
    }
}

export const addCompnayCodeToUser = (companyCode: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any): Promise<void> => {
        try {
            const { companies }: { companies: Types.companyReduxState } = getState()
            dispatch(beginApiCall())

            const newCompany = await NetworkManager.addCompnayCodeToUser(companies.currentCompany!.id!, companyCode) as Types.Company

            dispatch(addCompnayCodeToUserSuccess(newCompany))
        } catch (error) {
            dispatch(apiCallError())
            throw error
        }
    }
}
