import { lazy } from 'react'
import * as StripeTypes from './stripeTypes'

const CheckMarkIcon = lazy(() => import('@mui/icons-material/Check'))
const UnlimitedIcon = lazy(() => import('@mui/icons-material/AllInclusive'))

//page URLs
export const loginPage = '/Login'
export const createAccountPage = '/CreateAccount/:companyCode'
export const createCompanyAccountPage = '/CreateCompanyAccount'
export const projectsPage = '/Projects'
export const projectPage = '/Project'
export const timelinePage = '/Timeline/:companyID/:projectID'
export const downloadPage = '/Download/:companyID/:projectID/:postID'
export const downloadPage2 = '/Download'
export const subscriptionPage = '/Subscription'
export const notificationsPage = '/Notifications'
export const manageTagsPage = '/ManageTags'
export const adminAnalyticsPage = '/AdminAnalytics'
export const profilePage = '/Profile'
export const emailVerificationPage = '/EmailVerification'
export const manageUsersPage = '/ManageUsers'
export const companySettingsPage = '/CompanySettings'
export const composeNotificationPage = '/ComposeNotification'
export const messagesPage = '/Messages'
export const calendarPage = '/Calendar'

export const toolTipTimeButton = 300
export const toolTipTimeTable = 300
export const toolTipSidebar = 300

export const drawerWidth = 240

export const footerHeight = 70

export const folderRoot = 'BuildsRoot_0'

export const stripeTest = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY

export const plans = [
    {
        name: 'Starter',
        id: 'price_1HN7DjLHbQOKxWWEqWb2yf1F',
    },
    {
        name: 'Professional',
        id: 'price_1HN7EwLHbQOKxWWEHRsQGZfv',
    },
    {
        name: 'Enterprise',
        id: 'price_1HN7GiLHbQOKxWWEpALR6y4M',
    },
]

export const planDescriptions: StripeTypes.PlanDescription[] = [
    {
        ...plans.filter(plan => plan.name === 'Starter')[0],
        projects: '10',
        support: '-',
        photoAsset: <CheckMarkIcon />,
        videoAsset: '-',
        extensions: '-',
        buildsHosted: <CheckMarkIcon />,
        clientHosted: '-',
    },
    {
        ...plans.filter(plan => plan.name === 'Professional')[0],
        projects: '400',
        support: <CheckMarkIcon />,
        photoAsset: <CheckMarkIcon />,
        videoAsset: '-',
        extensions: '-',
        buildsHosted: <CheckMarkIcon />,
        clientHosted: <CheckMarkIcon />,
    },
    {
        ...plans.filter(plan => plan.name === 'Enterprise')[0],
        projects: <UnlimitedIcon />,
        support: <CheckMarkIcon />,
        photoAsset: <CheckMarkIcon />,
        videoAsset: <CheckMarkIcon />,
        extensions: <CheckMarkIcon />,
        buildsHosted: <CheckMarkIcon />,
        clientHosted: <CheckMarkIcon />,
    },
]

export enum userRoles {
    ACCOUNT_OWNER = 1,
    ADMIN = 2,
    EMPLOYEE = 3,
    CLIENT = 4,
}
