import { createStore, applyMiddleware } from 'redux'
import rootReducer from '../reducers/index'
import thunk from 'redux-thunk'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage,
}

const persistedStore = persistReducer(persistConfig, rootReducer)

const store = () => {
    let store = createStore(persistedStore, {}, applyMiddleware(thunk))
    let persistor = persistStore(store)
    return { store, persistor }
}

export default store
