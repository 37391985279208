import { apiStatusActions } from '../actions/apiStatusActions'
import * as Types from 'resources/types'

function actionTypeEndsInSccuess(actionType: string) {
    return actionType.substring(actionType.length - 8) === '_SUCCESS'
}

const apiStatusReducer = (state: Types.apiStatusReduxState = { 
    apiCallsInProgess: 0,
}, action: apiStatusActions) => {
    if (action.type === 'BEGIN_API_CALL') {
        
        state = {
            apiCallsInProgess: (state.apiCallsInProgess =
            state.apiCallsInProgess + 1),
        }
    } else if (action.type === 'ZERO_OUT_API_CALLS') {
        
        state = {
            apiCallsInProgess: 0,
        }
    } else if (action.type === 'API_CALL_ERROR' || actionTypeEndsInSccuess(action.type)) {
        
        let tempApiCallsInProgress = state.apiCallsInProgess

        if (tempApiCallsInProgress > 0) {
            tempApiCallsInProgress = tempApiCallsInProgress - 1
        } else {
            tempApiCallsInProgress = 0
        }

        state = {
            apiCallsInProgess: tempApiCallsInProgress,
        }
    }

    return state
}

export default apiStatusReducer
