import * as Types from 'resources/types'
import { postsActions } from '../actions/postsActions'

const PostsReducer = (state: Types.postsReduxState = {
    currentProjectID: '',
    allPosts: [],
}, action: postsActions) => {
    
    switch (action.type) {
        case 'GET_ALL_POSTS_SUCCESS':
            
            state = {
                ...state,
                currentProjectID: action.projectID,
                allPosts: action.posts,
            }

            break

        case 'CREATE_POST_SUCCESS':
            // since the post table view is tied to a real time firestore feature we dont need to add the new post like this.

            break

        case 'UPDATE_POST_SUCCESS':
            // since the post table view is tied to a real time firestore feature we dont need to update a post like this.

            break

        case 'DELETE_POST_SUCCESS':
            // since the post table view is tied to a real time firestore feature we dont need to delete a post like this.

            break

        case 'SET_SELECTED_PROJECT':
            
            state = {
                currentProjectID: action.project.id!,
                allPosts: action.project.id === state.currentProjectID ? state.allPosts : [],
            }

            break

        case 'SET_CURRENT_STATE_SIGNNED_OUT':
            
            state = {
                currentProjectID: '',
                allPosts: [],
            }

            break

        default:
            break
    }

    return state
}

export default PostsReducer
