import { FC, ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from './redux/reducers/index'
import {
    ThemeProvider,
    StyledEngineProvider,
} from '@mui/material/styles';
import { BuildsTheme } from '@jaymathew/elements/ThemeBuilds360'

interface ThemeWrapperProps {
    children?: ReactNode
}

const ThemeWrapper: FC<ThemeWrapperProps> = ({ children }) => {
    const appSettings = useSelector((state: AppState) => state.appSettings)

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={BuildsTheme(appSettings)} >
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default ThemeWrapper
