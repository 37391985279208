import { FC } from 'react'
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

const useStyles = makeStyles(({ palette, spacing, typography }: Theme) => ({
    rootContainer: (props: any) => ({
        backgroundColor: props.shouldBeForComponents ? 'transparent' : palette.background.default,
        minHeight: props.shouldBeForComponents ? '100%' : '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    }),
    title: {
        fontWeight: typography.fontWeightLight,
        color: palette.text.primary,
    },
    circularProgress: {
        marginTop: spacing(2),
    },
}))

interface LoadingScreenProps {
    shouldBeForComponents?: boolean
}

const LoadingScreen: FC<LoadingScreenProps> = ({ shouldBeForComponents }) => {
    const classes = useStyles({ shouldBeForComponents })

    return (
        <div className={classes.rootContainer}>
            <Typography variant="subtitle1" className={classes.title}>
                Loading...
            </Typography>
            <CircularProgress
                size={50}
                thickness={1}
                color="primary"
                className={classes.circularProgress}
            />
        </div>
    )
}

export default LoadingScreen
