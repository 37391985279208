import { combineReducers } from 'redux'
import * as Types from 'resources/types'

import appSettings from './appSettingsReducers'
import currentUser from './userReducer'
import apiStatus from './apiStatusReducer'
import companies from './companyReducers'
import projects from './projectsReducer'
import timelineProject from './timelineReducer'
import posts from './postsReducer'
import users from './usersReducer'
import messages from './messagesReducer'
import charges from './chargeReducer'

export interface AppState {
    appSettings: Types.appSettingsReduxState
    currentUser: Types.userReduxState
    companies: Types.companyReduxState
    projects: Types.projectsReduxState
    timelineProject: Types.timelineProjectReduxState
    apiStatus: Types.apiStatusReduxState
    posts: Types.postsReduxState
    users: Types.usersReduxState
    messages: Types.messagesReduxState
    charges: Types.chargesReduxState
}

const rootReducer = combineReducers({
    appSettings,
    currentUser,
    companies,
    projects,
    timelineProject,
    posts,
    users,
    messages,
    apiStatus,
    charges,
})

export default rootReducer
