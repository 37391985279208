import { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import * as Constants from 'resources/constants'
import ThemeWrapper from '../themeWrapper'
import ScrollToTop from './ScrollToTop'

import { PersistGate } from 'redux-persist/integration/react'
import ConfigureStore from 'redux/store/configureStore'
import { Provider } from 'react-redux'

import LoginPage from 'views/login/loginPage'
import LoadingScreen from 'shared/loadingScreen'

const EmailVerificationPage = lazy(() => import('views/emailVerification/emailVerificationPage'))
const CreateAccountPage = lazy(() => import('views/createAccount/createAccountPage'))
const CreateCompanyAccountPage = lazy(() => import('views/createCompanyAccount/createCompanyAccountPage'))
const RootPage = lazy(() => import('views/root/rootPage'))
const ProjectsPage = lazy(() => import('views/Projects/projectsPage'))
const ProjectPage = lazy(() => import('views/project/projectPage'))
const ManageUsersPage = lazy(() => import('views/manageUsers/manageUsersPage'))
const ManageTagsPage = lazy(() => import('views/manageTags/manageTagsPage'))
const EditProfile = lazy(() => import('views/editProfile/profilePage'))
const TimelinePage = lazy(() => import('views/timeline/timelinePage'))
const DownloadPage = lazy(() => import('views/downloadPage/downloadPage'))
const SubscriptionPage = lazy(() => import('views/wallet/walletPage'))
const MessagesPage = lazy(() => import('views/messages/messagesPage'))
const CalendarPage = lazy(() => import('views/calendar/calendarPage'))
const CompanySettingsPage = lazy(() => import('views/companySettings/companySettingsPage'))
const AdminAnalyticsPage = lazy(() => import('views/companyAnalytics/companyAnalyticsPage'))

const { persistor, store } = ConfigureStore()

const routes = () => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ThemeWrapper>
                <BrowserRouter>
                    <ScrollToTop>
                        <Suspense fallback={<LoadingScreen />}>
                            <Routes>
                                <Route path="/" element={<LoginPage />} />
                                <Route path={Constants.loginPage} element={<LoginPage />} />
                                <Route path={Constants.emailVerificationPage} element={<EmailVerificationPage />} />
                                <Route path={Constants.createAccountPage} element={<CreateAccountPage />} />
                                <Route path={Constants.createCompanyAccountPage} element={<CreateCompanyAccountPage />} />
                                <Route path={Constants.timelinePage} element={<TimelinePage />} />
                                <Route path={Constants.downloadPage} element={<DownloadPage />} />

                                <Route path={Constants.projectsPage} element={<RootPage><ProjectsPage /></RootPage>} />
                                <Route path={Constants.projectPage} element={<RootPage><ProjectPage /></RootPage>} />
                                <Route path={Constants.manageUsersPage} element={<RootPage><ManageUsersPage /></RootPage>} />
                                <Route path={Constants.manageTagsPage} element={<RootPage><ManageTagsPage /></RootPage>} />
                                <Route path={Constants.profilePage} element={<RootPage><EditProfile /></RootPage>} />
                                <Route path={Constants.subscriptionPage} element={<RootPage><SubscriptionPage /></RootPage>} />
                                <Route path={Constants.messagesPage} element={<RootPage><MessagesPage /></RootPage>} />
                                <Route path={Constants.calendarPage} element={<RootPage><CalendarPage /></RootPage>} />
                                <Route path={Constants.companySettingsPage} element={<RootPage><CompanySettingsPage /></RootPage>} />
                                <Route path={Constants.adminAnalyticsPage} element={<RootPage><AdminAnalyticsPage /></RootPage>} />
                            </Routes>
                        </Suspense>
                    </ScrollToTop>
                </BrowserRouter>
            </ThemeWrapper>
        </PersistGate>
    </Provider>
)

export default routes
